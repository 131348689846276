/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  HStack,
  Stack,
  Text,
  Box,
  Heading,
  Link,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as React from 'react';
import { Auth, API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import PieChart from '../../../charts/dailyScans';
import { ChartProps, ReportProps } from '../../typings';
import StaticMap from '../../map/map';
import DataExportSection from '../data-export/data-export';

const AdvancedReportingSection = ({
  id,
  loading,
  setLoading,
  viewable,
}: ReportProps): JSX.Element => {
  const [dateRange, setDateRange] = React.useState([null, null]);
  const { currentAccount } = useSelector((state: any) => state.account);
  const [advancedLoading, setAdvancedLoading] = React.useState(false);

  // Setup after the useEffect
  const [byDeviceData, setByDeviceData] = React.useState({});
  const [byLocationData, setByLocationData] = React.useState([]);
  // const [byDateData, setByDateData] = React.useState({});
  const [mostScanned, setMostScanned] = React.useState([]);

  function isEmpty(obj: any) {
    if (obj) {
      // console.log(obj);
      return Object.keys(obj).length === 0;
    }
    return null;
  }

  const getAccountScansReport = async (queryDateRange?: Array<Date>) => {
    let res: {
      scansByDate: { [key: string]: string };
      scansByDevice: React.SetStateAction<ChartProps>;
      scansByLocation: React.SetStateAction<Array<string>>;
      mostScannedAssets: React.SetStateAction<any[]>;
    };
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      if (queryDateRange) {
        const newStartDate = queryDateRange[0].toISOString().split('T')[0];
        const newEndDate = queryDateRange[1].toISOString().split('T')[0];
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/scans/report/advanced?from=${newStartDate}&to=${newEndDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/scans/report/advanced `,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      // const sortedScansByDate = Object.keys(res.scansByDate)
      //   .sort()
      //   .reduce((r, k) => ((r[k] = res.scansByDate[k]), r), {});

      setByDeviceData(res.scansByDevice);
      // setByDateData(sortedScansByDate);
      setByLocationData(Object.keys(res.scansByLocation));
      setMostScanned(res.mostScannedAssets);
      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  const getProjectScansReport = async (
    projectId: string,
    queryDateRange?: Array<Date>
  ) => {
    try {
      let res: {
        scansByDate: { [x: string]: any };
        scansByDevice: React.SetStateAction<ChartProps>;
        scansByLocation: React.SetStateAction<Array<string>>;
        mostScannedAssets: React.SetStateAction<any[]>;
      };
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      if (queryDateRange) {
        const newStartDate = queryDateRange[0].toISOString().split('T')[0];
        const newEndDate = queryDateRange[1].toISOString().split('T')[0];
        res = await API.get(
          'openscreen',
          `projects/${projectId}/scans/report/advanced?from=${newStartDate}&to=${newEndDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `projects/${projectId}/scans/report/advanced`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      console.log(res);

      // const { scansByDate } = res;
      // const sortedScansByDate = Object.keys(scansByDate)
      //   .sort()
      //   .reduce((r, k) => ((r[k] = scansByDate[k]), r), {});

      setByDeviceData(res.scansByDevice);
      // setByDateData(sortedScansByDate);
      setByLocationData(Object.keys(res.scansByLocation));
      setMostScanned(res.mostScannedAssets);
      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (currentAccount?.accountId) {
      if (id.toLowerCase() === 'account') {
        getAccountScansReport();
      } else if (id) {
        getProjectScansReport(id);
      }
    }
  }, [id, currentAccount?.accountId]);

  const onDateChange = (startDate: Date, endDate: Date) => {
    setDateRange([startDate, endDate]);
    setAdvancedLoading(true);
    if (currentAccount?.accountId) {
      if (id.toLowerCase() === 'account') {
        // GET Report by accountId
        getAccountScansReport(dateRange)
          .then(() => {
            setAdvancedLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setAdvancedLoading(false);
          });
      } else if (id) {
        setAdvancedLoading(true);
        getProjectScansReport(id, dateRange)
          .then(() => {
            setAdvancedLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setAdvancedLoading(false);
          });
      }
    }
    setAdvancedLoading(false);
  };

  // If a user is not an advanced user, do not get the data, just use placeholder stuff and BLUR

  return (
    <Box w="50vw">
      <Stack>
        <Skeleton
          isLoaded={loading}
          marginBottom="8px"
          padding="20px"
          bgColor="#fff"
          h="137px"
        >
          <HStack>
            <Heading fontSize="18pt">Advanced Report</Heading>
            <Link
              href="/app/account/subscription-plan"
              display={viewable ? 'none' : 'initial'}
            >
              (Upgrade)
            </Link>
          </HStack>
          <Box display={viewable ? 'initial' : 'none'}>
            <RangeDatePicker
              onChange={onDateChange}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              startDatePlaceholder="From"
              endDatePlaceholder="To"
            />
          </Box>
          <Flex
            width="100%"
            marginY="25px"
            justify="center"
            align="center"
            display={viewable ? 'none' : 'initial'}
          >
            <Text>
              You do not have access to plan, please upgrade to gain access
            </Text>
          </Flex>
        </Skeleton>

        <Stack height="100%" w="100%" align="start">
          <Skeleton
            isLoaded={loading && !advancedLoading}
            width="100%"
            height="100%"
            justify="space-between"
            direction="column"
          >
            <StaticMap test={byLocationData} />
          </Skeleton>
          <Flex
            height="100%"
            width="100%"
            direction="row"
            align="start"
            justify="space-between"
          >
            <Flex width="100%" justify="space-evenly">
              <Skeleton
                width="90%"
                bgColor="#fff"
                marginY="5px"
                isLoaded={loading && !advancedLoading}
              >
                <Heading paddingX="20px" marginY="10px" fontSize="18pt">
                  Most Scanned
                </Heading>
                {!viewable && (
                  <Text padding="5px" textAlign="center">
                    To gain access to this feature, please upgrade to an
                    enterprise plan
                  </Text>
                )}
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th fontSize="10pt">QRID</Th>
                      <Th fontSize="10pt">NAME</Th>
                      <Th fontSize="10pt">SCANS</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {viewable &&
                      mostScanned &&
                      mostScanned.map((e) => (
                        <Tr justify="space-between">
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            fontSize="10pt"
                          >
                            {e.LocatorKey}
                          </Td>
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            fontSize="10pt"
                          >
                            {e.Name}
                          </Td>
                          <Td fontSize="10pt">{e.ScanCount}</Td>
                        </Tr>
                      ))}
                    {!viewable && (
                      <>
                        <Tr justify="space-between">
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW="95px"
                            fontSize="10pt"
                          >
                            a1b2c3
                          </Td>
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW="95px"
                            fontSize="10pt"
                          >
                            My QR Code
                          </Td>
                          <Td fontSize="10pt">2020</Td>
                        </Tr>
                        <Tr justify="space-between">
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW="95px"
                            fontSize="10pt"
                          >
                            v2a45mkj
                          </Td>
                          <Td
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW="95px"
                            fontSize="10pt"
                          >
                            My second QR
                          </Td>
                          <Td fontSize="10pt">2021</Td>
                        </Tr>
                      </>
                    )}
                  </Tbody>
                </Table>
              </Skeleton>
              <Skeleton
                marginY="5px"
                width="100%"
                bgColor="#fff"
                maxW="300px"
                marginX="15px"
                isLoaded={loading && !advancedLoading}
              >
                <Flex align="center" justify="center">
                  {mostScanned.length === 0 ? (
                    <Text h="200px">No Scans</Text>
                  ) : (
                    <></>
                  )}
                </Flex>
                <Flex direction="column" w="100%" isLoaded={loading}>
                  <Heading paddingX="20px" marginY="10px" fontSize="18pt">
                    Scans By Device
                  </Heading>
                  {!viewable && (
                    <Text padding="5px" textAlign="center">
                      To gain access to this feature, please upgrade to an
                      enterprise plan
                    </Text>
                  )}
                  <Flex justify="center" align="start" paddingBottom="20px">
                    {isEmpty(byDeviceData) ? (
                      <Text>No Scans in date range</Text>
                    ) : (
                      <PieChart
                        labels={Object.keys(byDeviceData)}
                        data={Object.values(byDeviceData)}
                      />
                    )}
                    {!viewable && (
                      <PieChart labels={['iOS', 'Android']} data={[1, 1]} />
                    )}
                  </Flex>
                </Flex>
              </Skeleton>
              <Skeleton
                marginY="5px"
                width="100%"
                maxW="300px"
                bgColor="#fff"
                isLoaded={loading}
              >
                <Box bgColor="#fff" padding="20px" height="100%">
                  <DataExportSection
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    id={id}
                  />
                </Box>
              </Skeleton>
            </Flex>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AdvancedReportingSection;
