/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import ReportingLevel from '../../menus/reporting/reportingLevel';
import AdvancedReportingSection from './advanced-reporting/advanced-reporting';
import BasicReportingSection from './basic-reporting/basic-reportings';

const ScanData = (): JSX.Element => {
  const [id, setID] = React.useState('account');
  const [loadedBasic, setLoadedBasic] = React.useState(false);
  const [loadedAdvanced, setLoadedAdvanced] = React.useState(false);
  const { currentAccount } = useSelector((state: any) => state.account);

  const handleBasicLoadChange = React.useCallback((val: boolean) => {
    setLoadedBasic(val);
  }, []);

  const handleAdvancedLoadChange = React.useCallback((val: boolean) => {
    setLoadedAdvanced(val);
  }, []);

  const handleLoading = (val: boolean) => {
    setLoadedAdvanced(val);
    setLoadedBasic(val);
  };

  React.useEffect(() => {
    console.log(loadedBasic, loadedAdvanced);
  }, [loadedBasic, loadedAdvanced]);

  return (
    <Box w="70vw" height="100%">
      <Flex align="start" justify="start">
        <Flex
          direction="column"
          marginLeft="20px"
          marginRight="15px"
          height="100%"
        >
          <Stack
            bgColor="#fff"
            justify="start"
            align="start"
            maxW="270px"
            padding="20px"
            marginBottom="15px"
            h="137px"
          >
            <Heading fontSize="23pt">Scan Data</Heading>
            <ReportingLevel setLoaded={handleLoading} setProjectId={setID} />
          </Stack>
          <Stack height="100%">
            <BasicReportingSection
              loading={loadedBasic && loadedAdvanced}
              setLoading={handleBasicLoadChange}
              id={id}
            />
          </Stack>
        </Flex>
        <Box height="100%">
          <AdvancedReportingSection
            viewable={currentAccount?.pricePlan.reporting !== 'basic'}
            loading={loadedBasic && loadedAdvanced}
            setLoading={handleAdvancedLoadChange}
            id={id}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ScanData;
