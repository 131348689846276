/* eslint-disable import/no-duplicates */
import * as React from 'react';
import './map.css';
// import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box } from '@chakra-ui/react';
import bbox from '@turf/bbox';
import { multiPoint } from '@turf/helpers';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Markers from './marker/marker';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiYWhtYWR5ZmFraHJ5IiwiYSI6ImNrdXd6bnUweTJsY3cydnBneWF0ZjJqdHQifQ.aTNtTD8lXTRX9UxtjXs0Kg';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};
interface Props {
  test: Array<string>;
}

// const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
//   c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
//   C20.1,15.8,20.2,15.8,20.2,15.7z`;

const StaticMap = ({ test }: Props): JSX.Element => {
  const mapContainerRef = React.useRef(null);

  const [map, setMap] = React.useState(null);
  const [places, setPlaces] = React.useState([]);

  React.useEffect(() => {
    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: 'mapbox://styles/mapbox/streets-v11',
      // Empire State Building [lng, lat]
      minZoom: 1, // note the camel-case
      center: [-73.9856, 40.7497],
      zoom: 3,
    });
    setMap(newMap);
    return () => newMap.remove();
  }, []);

  React.useEffect(() => {
    if (!map) return;

    if (places.length !== 0) {
      const coords: any[][] = [];
      places.forEach((place) => {
        coords.push([place.longitude, place.latitude]);
      });
      const feature = multiPoint(coords);
      const box = bbox(feature);

      map.fitBounds(
        [
          [box[0], box[1]],
          [box[2], box[3]],
        ],
        {
          padding: 20,
          maxZoom: 1,
          duration: 2000,
        }
      );
    }
  }, [map]);

  React.useEffect(() => {
    const newPlaces = test.map((e) => {
      const coords = e.split(',').map((e) => Number(e));
      return {
        latitude: coords[0],
        longitude: coords[1],
      };
    });
    setPlaces(newPlaces);
  }, []);

  // Handlers

  return (
    <Box ref={mapContainerRef} style={mapContainerStyle}>
      {places && map && <Markers map={map} places={places} />}
    </Box>
  );
};

export default StaticMap;
