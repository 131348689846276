import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

interface ChartProps {
  labels?: Array<string>;
  data?: Array<number>;
}

const PieChart = ({
  labels = ['WEB', 'ANDROID', 'APPLE'],
  data = [12, 19, 3, 5],
}: ChartProps): JSX.Element => {
  // Could seperate per project

  const parsedData = data.map((i) => Number(i));
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Devices',
        data: parsedData,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Flex maxW="300px" maxH="300px">
      <Doughnut height={200} width={200} data={chartData} />
    </Flex>
  );
};

export default PieChart;
