import * as React from 'react';
import { Stack, Flex, Text, Heading, Skeleton } from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { ReportProps } from '../../typings';

interface ReportInt {
  projectsTotal: string;
  staticQRCodesTotal: string;
  dynamicQRCodesTotal: string;
  scansTotal: string;
  lastScanDate: string;
  lastScanLocation: string;
}

const BasicReportingSection = ({
  id,
  loading,
  setLoading,
}: ReportProps): JSX.Element => {
  const [reportData, setReportData] = React.useState<ReportInt>({
    projectsTotal: '0',
    staticQRCodesTotal: '0',
    dynamicQRCodesTotal: '0',
    scansTotal: '0',
    lastScanDate: 'None',
    lastScanLocation: 'None',
  });
  const { currentAccount } = useSelector((state: any) => state.account);

  const getAccountScansReport = async () => {
    let res;
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      res = await API.get(
        'openscreen',
        `accounts/${currentAccount?.accountId}/scans/report/basic `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const filteredLastScanLocation = res.lastScanLocation
        .split(',')
        .map((e: string) => {
          if (e !== 'undefined') {
            return e;
          }
          return '';
        })
        .join(', ');

      setReportData({ ...res, lastScanLocation: filteredLastScanLocation });
      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  const getProjectScansReport = async (projectId: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get(
        'openscreen',
        `projects/${projectId}/scans/report/basic`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const filteredLastScanLocation = res.lastScanLocation
        .split(',')
        .map((e: string) => {
          if (e !== 'undefined') {
            return e;
          }
          return '';
        })
        .join(', ');

      setReportData({ ...res, lastScanLocation: filteredLastScanLocation });

      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (currentAccount?.accountId) {
      if (id.toLowerCase() === 'account') {
        getAccountScansReport();
      } else if (id) {
        getProjectScansReport(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentAccount?.accountId]);

  return (
    <Skeleton
      minH="683px"
      isLoaded={loading}
      bgColor="#fff"
      marginBottom="15px"
    >
      <Stack spacing={5} padding="15px">
        <Heading fontSize="18pt">Basic Report</Heading>
        {/* Projects Section */}

        <Flex
          display={id.toLowerCase() === 'account' ? 'flex' : 'none'}
          justify="space-between"
        >
          <Text fontWeight="bold">Total Projects</Text>
          <Text>{reportData.projectsTotal}</Text>
        </Flex>

        {/* Static QR Codes Section */}
        <Stack margin="20px">
          <Text fontWeight="bold">Static QR Codes</Text>
          <Flex
            overflow="hidden"
            textOverflow="ellipsis"
            justify="space-between"
          >
            <Text>Total QR Codes</Text>
            <Text>{reportData.staticQRCodesTotal}</Text>
          </Flex>
        </Stack>

        {/* Static QR Codes Section */}
        <Stack marginY="20px">
          <Text fontWeight="bold">Dynamic QR Codes </Text>
          <Flex justify="space-between">
            <Text>Total QR Codes</Text>
            <Text>{reportData.dynamicQRCodesTotal}</Text>
          </Flex>
          <Flex justify="space-between">
            <Text>Total Scans</Text>
            <Text>{reportData.scansTotal}</Text>
          </Flex>
          <Flex
            direction="column"
            overflow="hidden"
            textOverflow="ellipsis"
            justify="space-between"
          >
            <Text textDecor="underline">Last Scan Date</Text>
            <Text
              maxW="100px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              justify="space-between"
            >
              {reportData.lastScanDate || 'Never'}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text textDecor="underline" whiteSpace="nowrap" marginRight="60px">
              Last Scan Location
            </Text>
            <Text overflow="hidden" textOverflow="ellipsis" maxW="200px">
              {reportData.lastScanLocation || 'None'}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Skeleton>
  );
};

export default BasicReportingSection;
