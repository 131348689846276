// markers.js
import * as React from 'react';
import mapboxgl from '!mapbox-gl';

const Marker = ({ map, place }) => {
  const markerRef = React.useRef();

  React.useEffect(() => {
    // console.log(place);
    const marker = new mapboxgl.Marker({ ...markerRef, color: '#ff884c' })
      .setLngLat([place.longitude, place.latitude])
      .addTo(map);

    return () => marker.remove();
  });

  return <div ref={markerRef} />;
};

const Markers = ({ map, places }) => {
  return (
    <>
      {places &&
        places.map((place: { latitude: number }) => (
          <Marker key={place.latitude} map={map} place={place} />
        ))}
    </>
  );
};

export default Markers;
