import * as React from 'react';
import {
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  Text,
  Link,
  Flex,
} from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import 'react-google-flight-datepicker/dist/main.css';
import { DataExportProps } from '../../typings';

const DataExportSection = ({
  id,
  startDate,
  endDate,
}: DataExportProps): JSX.Element => {
  const initStartDate = new Date();
  initStartDate.setDate(initStartDate.getDate() - 7);
  const [viewable, setViewable] = React.useState(true);
  const [value, setValue] = React.useState('1');
  const { currentAccount } = useSelector((state: any) => state.account);

  React.useEffect(() => {
    setViewable(currentAccount?.pricePlan?.dataExport);
  }, []);

  const onSubmit = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const newStartDate = startDate.toISOString().split('T')[0];
      const newEndDate = endDate.toISOString().split('T')[0];
      let headers;
      if (value === 'csv') {
        headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'text/csv',
        };
      } else {
        headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        };
      }
      if (id.toLowerCase() === 'account') {
        await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/scans/export?format=${value}&from=${newStartDate}&to=${newEndDate}`,
          {
            headers,
          }
        );
      } else {
        await API.get(
          'openscreen',
          `projects/${id}/scans/export/?format=${value}&from=${newStartDate}&to=${newEndDate}`,
          {
            headers,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack width="100%" minW="150px" justify="center">
      <HStack marginY="5px">
        <Heading fontSize="18pt">Data Export</Heading>
        <Link
          href="/app/account/subscription-plan"
          display={viewable ? 'none' : 'initial'}
        >
          (Upgrade)
        </Link>
      </HStack>
      <Flex
        width="100%"
        marginY="25px"
        justify="center"
        align="center"
        display={viewable ? 'none' : 'initial'}
      >
        <Text>
          You do not have access to plan, please upgrade to gain access
        </Text>
      </Flex>
      <RadioGroup
        disabled={!viewable}
        flexDirection="row"
        onChange={setValue}
        marginX="20px"
      >
        <Radio marginRight="10px" size="md" value="csv" colorScheme="blue">
          CSV
        </Radio>
        <Radio size="md" value="json" colorScheme="blue">
          JSON
        </Radio>
      </RadioGroup>
      <Button
        disabled={!viewable}
        onClick={onSubmit}
        borderRadius="55px"
        paddingX="10px"
        paddingY="25px"
        width="100%"
        bgColor="#4fc1ff"
        color="#fff"
        _hover={{
          bgColor: '#000',
        }}
      >
        Download
      </Button>
    </Stack>
  );
};

export default DataExportSection;
